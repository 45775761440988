<template>
  <div id="member">
    <cent-title title="会员信息" />
    <div v-if="!isLimints">
      <div class="user">
        <div>订单编号：</div>
        <el-input
          v-model.trim="orderNum"
          placeholder="请输入订单编号"
          clearable
          @clear="clearInfo"
        ></el-input>
        <el-button @click="queryOrderNum">查询</el-button>
        <div>客户编号：</div>
        <el-input
          v-model.trim="userName"
          placeholder="请输入客户编号"
          clearable
          @clear="clearInfo"
        ></el-input>
        <el-button @click="querySerial">查询</el-button>
        <div>会员等级：</div>
        <el-select
          v-model="vipLevel"
          @change="changeGrade"
          placeholder="请选择等级"
          clearable
          @clear="clearInfo"
        >
          <el-option
            v-for="item in options"
            :key="item.code"
            :label="item.value"
            :value="item.code"
          >
          </el-option>
        </el-select>
        <el-button @click="queryGrade">查询</el-button>
      </div>
      <!-- <el-button size="mini" icon="el-icon-delete" class="delete">删除</el-button> -->
      <el-table :data="memberList" border style="width: 100%">
        <el-table-column
          type="selection"
          width="50"
          align="center"
        ></el-table-column>
        <el-table-column
          type="index"
          label="序号"
          width="80"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="orderNum"
          label="订单编号"
          width="180"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="userNum"
          label="客户编号"
          width="180"
          align="center"
        ></el-table-column>
        <el-table-column label="相亲卡" width="160" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.cards != ''">
              <el-image
                :src="scope.row.cards[0].cardUrl"
                :preview-src-list="previewList"
                @click="getCardUrl(scope.row.cards)"
                lazy
                style="width: 51px; height: 48px"
              />
            </div>
            <div v-else>
              <el-image
                :src="require('../../assets/img/business/error.png')"
                lazy
                style="width: 51px; height: 48px"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="last"
          label="会员期限"
          width="180"
          align="center"
        ></el-table-column>

        <!-- <el-table-column
          prop="numberMatch"
          label="匹配次数"
          width="180"
          align="center"
        ></el-table-column> -->

        <!-- <el-table-column
          label="匹配次数"
          width="180"
          align="center"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.numberMatch === -1">永久</div>
            <div v-else>{{ scope.row.numberMatch }}</div>
          </template>
        </el-table-column> -->


        <el-table-column
          prop="startTime"
          label="开始时间"
          width="180"
          align="center"
        ></el-table-column>
        <el-table-column label="会员等级" width="180" align="center">
          <template slot-scope="scope">
            <!-- <div v-if="scope.row.vipLevel === 0">普通客户</div>
            <div v-else-if="scope.row.vipLevel === 1">全球单线</div>
            <div v-else-if="scope.row.vipLevel === 10">全球三个月</div>
            <div v-else-if="scope.row.vipLevel === 100">全球八个月</div>
            <div v-else-if="scope.row.vipLevel === 1000">全球永久</div>
            <div v-else-if="scope.row.vipLevel === 2">同国单线</div>
            <div v-else-if="scope.row.vipLevel === 20">同国三个月</div>
            <div v-else-if="scope.row.vipLevel === 200">同国八个月</div>
            <div v-else-if="scope.row.vipLevel === 2000">同国永久</div>
            <div v-else-if="scope.row.vipLevel === 3">同城单线</div>
            <div v-else-if="scope.row.vipLevel === 30">同城三个月</div>
            <div v-else-if="scope.row.vipLevel === 300">同城八个月</div>
            <div v-else-if="scope.row.vipLevel === 3000">同城永久</div> -->
            <div v-if="scope.row.vipLevel === 0">普通客户</div>
            <div v-else-if="scope.row.vipLevel === 1">普卡VIP</div>
            <div v-else-if="scope.row.vipLevel === 10">金卡VIP</div>
            <div v-else-if="scope.row.vipLevel === 100">铂金VIP</div>
          </template>
        </el-table-column>
        <el-table-column label="会员状态" width="180" align="center">
          <template slot-scope="scope">
            <div class="past" v-if="scope.row.vipStatus == -1">过期</div>
            <div class="pass" v-else-if="scope.row.vipStatus == 1">在期</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="endTime"
          label="截止期限"
          width="180"
          align="center"
        ></el-table-column>
        <el-table-column prop="" label="操作" width="" align="center">
          <template slot-scope="scope">
            <el-button
              class="update"
              icon="el-icon-edit"
              @click="extensionEdit(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="page"
        :page-sizes="[100, 200, 300, 400]"
        :page-size="pageSize"
        prev-text="上一页"
        next-text="下一页"
        layout="total, prev, pager, next,jumper,slot"
        :total="total"
      >
      </el-pagination>
      <!-- 修改弹框 -->
      <my-amend v-show="isAmend" @btnClose="isAmend = false">
        <el-form
          :model="memberForm"
          :rules="memberRules"
          ref="memberForm"
          label-width="100px"
          class="memberForm"
        >
          <div class="isTitle">客户信息</div>
          <el-form-item label="客户编号：" prop="userName">
            <el-input
              v-model="memberForm.userName"
              placeholder="请填写客户编号"
              maxlength="20"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="开始时间：" prop="startTime">
            <el-input
              v-model="memberForm.startTime"
              placeholder="请填写客户编号"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item label="结束时间：" prop="endTime">
            <el-input
              v-model="memberForm.endTime"
              placeholder="请填写客户编号"
              maxlength="20"
            ></el-input>
          </el-form-item>

          <!-- <el-form-item label="匹配次数" prop="numberMatch">
            <el-input
              v-model="memberForm.numberMatch"
              placeholder="请填写客户编号"
            ></el-input>

          </el-form-item> -->

          <el-form-item class="sub">
            <el-button class="subBtn" @click="onSubmit('memberForm')"
              >确定</el-button
            >
          </el-form-item>
        </el-form>
      </my-amend>
    </div>
    <div v-else>
      <limits tips="无权限"></limits>
    </div>
  </div>
</template>

<script>
import centTitle from "../../components/content/centTitle.vue";
import myAmend from "../../components/case/amend.vue";

import { request } from "../../API/request";
import Limits from "../../components/common/limits.vue";

export default {
  components: {
    centTitle,
    myAmend,
    Limits,
  },
  data() {
    return {
      orderNum: "", // 客户编号
      userName: "", // 客户姓名
      vipLevel: "", // 会员等级
      options: [
        { code: 0, value: "普通客户" },
        { code: 1, value: "普卡VIP" },
        { code: 10, value: "金卡VIP" },
        { code: 100, value: "铂金VIP" },
      ], // 会员等级选项
      memberList: [], // 表格数据
      memberForm: {}, // 修改数据
      // 表单校检
      memberRules: {
        startTime: [
          {
            required: true,
            message: "会员的创建时间不能为空",
            trigger: "blur",
          },
        ],
        endTime: [
          {
            required: true,
            message: "会员的结束时间不能为空",
            trigger: "blur",
          },
        ],
        // numberMatch: [
        //   {
        //     required: true,
        //     message: '匹配次数不能为空',
        //     trigger: 'blur'
        //   }
        // ]
      },
      query: 0, // 查询的数据类型(0 默认 1订单编号 2客户编号 3会员等级)
      page: 1, // 当前页数
      pageSize: 8, // 每页条数
      total: 0, // 总条数
      index: 0, // 操作的数据索引
      isAmend: false, // 修改内容组件
      previewList: [1], // 图片预览图
      isLimints: false, // 是否有权限
    };
  },
  created() {
    this.getMemberList();
  },
  methods: {
    // 获取会员信息
    getMemberList() {
      let params = {
        page: this.page,
        orderNum: this.orderNum,
        userNum: this.userName,
        vipLevel: this.vipLevel,
        size: this.pageSize,
      };
      if (this.query === 1) {
        delete params.userNum;
        delete params.vipLevel;
      } else if (this.query === 2) {
        delete params.orderNum;
        delete params.vipLevel;
      } else if (this.query === 3) {
        delete params.userNum;
        delete params.orderNum;
      }
      request({
        url: "/vip/get",
        method: "GET",
        params,
      }).then((res) => {
        if (res.code === 200) {
          this.memberList = res.data.records;
          this.total = res.data.total;
        } else if (res.code === 502) {
          this.isLimints = true;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 清空选框中的内容
    clearInfo() {
      this.page = 1;
      this.getMemberList();
    },
    // 查询订单编号
    queryOrderNum() {
      if (this.orderNum == "") {
        this.$message({ message: "查询订单编号不能为空！", type: "error" });
      } else {
        this.query = 1;
        this.getMemberList();
      }
    },
    // 查询客户编号
    querySerial() {
      if (this.userName === "") {
        this.$message({ message: "查询客户编号不能为空！", type: "error" });
      } else {
        this.query = 2;
        this.getMemberList();
      }
    },
    // 会员等级选框
    changeGrade(e) {
      this.vipLevel = e;
    },
    // 查询会员等级
    queryGrade() {
      if (this.vipLevel === "") {
        this.$message({ message: "请先选择查询的会员等级！", type: "error" });
      } else {
        this.query = 3;
        this.getMemberList();
      }
    },
    // 当前页数
    handleCurrentChange(val) {
      this.page = val;
      this.getMemberList();
    },
    // 修改用户信息
    extensionEdit(row) {
      this.memberForm = JSON.parse(JSON.stringify(row));
      this.isAmend = true;
    },
    // 确定修改
    onSubmit(memberForm) {
      this.$refs[memberForm].validate((valid) => {
        if (valid) {
          if (
            new Date(this.memberForm.startTime) <
            new Date(this.memberForm.endTime)
          ) {
            request({
              url: "/vip/editTime",
              method: "POST",
              data: {
                vipId: this.memberForm.id,
                cid: this.memberForm.cid,
                startTime: this.memberForm.startTime,
                endTime: this.memberForm.endTime,
                // numberMatch: this.memberForm.numberMatch
              },
            }).then((res) => {
              if (res.code === 200) {
                this.$message.success("操作成功！");
                this.getMemberList();
                this.isAmend = false;
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            this.$message.warning("开始日期不能大于结束日期！");
          }
        } else {
          this.$message.error("信息填写有误，请检查！");
          return false;
        }
      });
    },
    // 显示相亲卡预览图
    getCardUrl(item) {
      let list = [];
      for (let i = 0; i < item.length; i++) {
        list.push(item[i].cardUrl);
      }
      this.previewList = list;
    },
  },
};
</script>

<style lang="less" scoped>
#member {
  .user {
    margin: 16px 0;
    padding: 10px 0 10px 24px;
    background: var(--gray);
    font-size: var(--subSize);
    font-weight: bold;
    display: flex;
    align-items: center;
    .el-input {
      width: 200px;
    }
    .el-button {
      background: var(--subPink);
      color: var(--white);
      font-size: var(--subSize);
      padding: 10px 24px;
      margin: 0 50px 0 12px;
    }
  }
  .update {
    padding: 10px 16px;
    font-size: 14px;
    color: var(--white);
    background: var(--green);
  }
  .delete {
    padding: 10px 16px;
    font-size: 14px;
    color: var(--white);
    background: var(--info);
  }
  .el-table {
    margin: 12px 0 20px;
    /deep/.cell {
      line-height: 1;
    }
    .pass {
      margin: 0 auto;
      width: 60px;
      padding: 6px;
      color: var(--white);
      background: var(--green);
      border-radius: 3px;
    }
    .past {
      margin: 0 auto;
      width: 60px;
      padding: 6px;
      color: var(--white);
      background: var(--info);
      border-radius: 3px;
    }
  }
  .el-pagination {
    display: flex;
    justify-content: flex-end;
    margin-right: 12px;
  }
  .memberForm {
    .isTitle {
      font-size: var(--mainSize);
      text-align: center;
      margin-bottom: 20px;
    }
    .sub {
      width: calc(100% - 100px);
      display: flex;
      justify-content: center;
      .subBtn {
        background: var(--pink);
        color: var(--white);
      }
    }
  }
}
</style>